<template>
    <header class="page-title">
        <h1><i class="bi bi-list-ul text-primary"></i> リスト管理</h1>
    </header>

    <section class="section">
        <inline-loader v-if="loading"></inline-loader>
        <div v-else>
            <div class="mx-1 mb-3 px-3">
                <p class="my-0">該当 : {{ conditions_total }} 件</p>
            </div>

            <table class="table" style="width: 55%">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-1"></th>
                        <th class="text-center col-7">リスト名</th>
                        <th class="text-center col-2">個人用/共通</th>
                        <th class="text-center col-1"></th>
                        <th class="text-center col-1"></th>
                        <th class="text-center col-1"></th>
                    </tr>
                </thead>
                <template v-if="conditions.length">
                    <draggable
                            v-model="conditions"
                            item-key="condition_id"
                            tag="tbody"
                            handle=".handle"
                            @end="sort()"
                    >
                        <template #item="{element}">
                            <tr>
                                <td class="text-center handle"><i class="bi bi-chevron-bar-expand"></i></td>
                                <td class="align-middle"><form-input v-model="element.condition_name"></form-input></td>
                                <td class="align-middle text-center">{{ element.condition_type_label }}</td>
                                <td class="align-middle text-center">
                                    <button @click="update(element)" type="button" class="btn btn-info">保存</button>
                                </td>
                                <td class="align-middle text-center">
                                    <button v-if="element.condition_type === ConditionType.PRIVATE || $store.getters['auth/canEditSharedList']" @click="removeReady(element)" type="button" class="btn btn-danger">削除</button>
                                    <button v-else type="button" class="btn btn-secondary" disabled>削除</button>
                                </td>
                                <td class="align-middle text-end">
                                    <router-link
                                        :to="{name: 'Project', params: {condition_id: element.condition_id}}"
                                        class="btn btn-outline-primary"
                                    >
                                        表示
                                    </router-link>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </template>
            </table>
        </div>
    </section>

    <confirm-dialog ref="confirmRemove" @ok="remove">
        <p>削除してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ConfirmDialog from '@/components/tools/ConfirmDialog.vue';
import FormInput from '@/components/forms/FormInput';
import Condition from '@/models/entities/condition';
import ConditionType from '@/models/enums/condition-type';

export default {
    name: 'Condition',
    components: {
        draggable,
        InlineLoader,
        FormInput,
        ConfirmDialog,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    mounted() {
        this.search();
    },
    data() {
        return {
            loading: false,
            ConditionType,

            conditions: [],
            conditions_total: null,

            condition_to_remove: null,
        }
    },
    methods: {
        search() {
            this.conditions.splice(0);
            this.loading = true;

            this.$http.get('/condition')
            .then(response => {
                for (let row of response.data) {
                    this.conditions.push(new Condition(row));
                }
                this.conditions_total = response.data.length;
            })
            .finally(() => {
                this.loading = false;
            })
        },
        //保存
        update(condition) {
            this.startScreenLoading();

            this.$http.put(`/condition/${condition.condition_id}`, condition)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        //削除確認
        removeReady(condition) {
            this.condition_to_remove = condition;
            this.$refs.confirmRemove.show();
        },
        //削除
        remove() {
            this.startScreenLoading();

            this.$http.delete(`/condition/${this.condition_to_remove.condition_id}`)
            .then(() => {
                // リストから削除
                let removed_index = this.conditions.findIndex((condition) => condition.condition_id === this.condition_to_remove.condition_id);
                this.conditions.splice(removed_index, 1);
                // 値をリセット
                this.condition_to_remove = null;

                this.showMessage('削除しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },

        //ソート
        sort() {
            this.startScreenLoading();
            const conditions = this.conditions.map(condition_object => condition_object.condition_id);

            this.$http.post('/condition/sort', {
                conditions: conditions,
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
